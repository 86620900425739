.container {
    background-color: #fff;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.label {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
}

.radio {
    margin-right: 10px;
}

.buyDiamonds {
    background-color: #00a252;
}

.sellDiamonds {
    background-color: #ea002c;
}

.submitBtn {
    color: #fff;
    padding: 17px 20px 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 3px;
    margin-left: 3px;
}

.submitBtn:hover {
    background-color: #0056b3;
}

.btnIcon {
    position: absolute;
    margin-top: -35px;
    margin-left: 26px;
}

.btnText {
    margin-top: 5px;
}

.mainHeading {
    margin-top: 2px;
}