.statusContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    padding: 0;
}

.statusContainer > button {
    background-color: red;
    border: 4px solid black;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: white;
    cursor: pointer;
}

.statusContainer > span {
    padding: 0;
    font-size: 20px;
    font-weight: bold;
    margin: -3px 0 0 0;
}

.statusContainer > button.done {
    background-color: green;
}

.statusContainer > button.active {
    background-color: #007BFF;
    border: 6px solid black;
    width: 40px;
    height: 40px;
}