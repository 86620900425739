.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    min-width: 270px;
    max-width: 73%;
}

.nameHolder {
    background-color: #0065d0;
    height: 50px;
    margin-top: -70px;
    border-radius: 5px 5px 0 0;
}

.nameHolder > h3 {
    color: floralwhite;
    font-weight: bolder;
    margin: 0;
    padding: 2px 0 0 0;
    text-align: center;
}

.nameHolder > h5 {
    color: lightcyan;
    font-weight: bolder;
    margin: 0;
    padding: 4px 0 8px 0;
    text-align: center;
}

.container > h2 {
    text-align: center;
    color: #00a252;
}

.resultCardSell {
    flex-direction: column;
}

.resultCardSellOrderId {
    color: lightseagreen;
    font-size: 1em;
    text-align: center;
    margin: -5px 0 5px;
    padding: 0;
}

.resultCardSellInfo {
    font-size: 1.3em;
    margin: 0 0 5px;
    padding: 0;
    text-align: center;
    color: blue;
}

.resultCardSellInfo2 {
    font-size: 1em;
    margin: 5px 0 0 0;
    padding: 0;
    text-align: center;
    color: red;
}

.form .note {
    text-align: center;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0;
}

.form label {
    display: block;
    margin-bottom: 10px;
    color: #333;
}

.form input {
    width: 92%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form input:read-only {
    background-color: #f0f0f0;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin: 6px 0 0 0;
}

.buttonContainer button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.buttonContainer button:hover {
    background-color: #0056b3;
}

.pkgContainer {
    margin: 7px 0;
    padding: 10px;
    width: auto;
    white-space: nowrap;
    overflow-x: auto;
}

.pkgContainer > button {
    margin: 0;
    padding: 0;
    outline: none;
    border: 0;
    background-color: transparent;
}

.pkgItem {
    box-shadow: 1px 1px 3px black;
    border-radius: 5px;
    /*background-color: orange;*/
    width: 100px;
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
}

.priceContainer, .diamondContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: 0;
    padding: 5px 0 5px 0;
}

.priceContainer {
    /*background-color: red;*/
}

.diamondContainer {
    /*background-color: blue;*/
}

.priceContainer > input {
    width: 25px;
    height: 18px;
    margin: 0 5px 0 -10px;
    padding: 0;
}

.priceContainer > img {
    width: 25px;
    height: 25px;
    margin-right: 3px;
}

.priceContainer > h4 {
    font-size: 15px;
    margin-left: 3px;
}

.diamondContainer > img {
    width: 45px;
    height: 45px;
    margin-right: 3px;
}

.diamondContainer > h4 {
    font-size: 20px;
    font-weight: bolder;
    margin-left: 3px;
}

.chooseMode {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}

.chooseMode > button {
    width: 46%;
    outline: none;
    background-color: transparent;
    border: 0;
    margin: 0 2%;
    padding: 0;
    box-shadow: 1px 1px 3px black;
    border-radius: 5px;
}

.chooseMode > button > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 4px 0 4px 0;
}

.chooseMode > button > div > input {
    margin: 0;
    padding: 0;
    width: 25px;
}

.chooseMode > button > div > h4 {
    white-space: nowrap;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
}

.chooseMode > button > img {
    width: 100%;
}

.qrHolder {

}

.qrHolder > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.qrHolder > div > img {
    width: 40px;
    height: 40px;
}

.qrHolder > div > span {
    font-size: 50px;
    font-weight: bold;
    margin: -5px 6px 0 6px;
}

.qrHolder > div > h3 {
    text-align: center;
}

.qrHolder > img {
    width: 100%;
    max-width: 350px;
}

.learn {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center
}

.learn h4 {
    margin: 0;
    padding: 5px 0;
    color: #007BFF;
    text-align: center;
}