.loadingBg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 150;
}

.loading {
    background-color: rgba(59, 59, 59, .7);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    /*display: none;*/
}

.loadingText {
    position: absolute;
    top: 60%;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 150;
    text-align: center;
    font-weight: bolder;
    display: none;
}