.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    min-width: 270px;
    max-width: 73%;
}

.nameHolder {
    background-color: #0065d0;
    height: 35px;
    margin-top: -55px;
    border-radius: 5px 5px 0 0;
}

.nameHolder > h3 {
    color: floralwhite;
    font-weight: bolder;
    margin: 0;
    padding: 6px 0 0 0;
    text-align: center;
}

.nameHolder > h5 {
    color: lightcyan;
    font-weight: bolder;
    margin: 0;
    padding: 4px 0 8px 0;
    text-align: center;
}

.container > h2 {
    text-align: center;
    color: #ea002c;
}

.resultCardSell {
    flex-direction: column;
}

.resultCardSellOrderId {
    color: lightseagreen;
    font-size: 1em;
    text-align: center;
    margin: -5px 0 5px;
    padding: 0;
}

.resultCardSellInfo {
    font-size: 1.3em;
    margin: 0 0 5px;
    padding: 0;
    text-align: center;
    color: blue;
}

.resultCardSellInfo2 {
    font-size: 1em;
    margin: 5px 0 0 0;
    padding: 0;
    text-align: center;
    color: red;
}

.form .haveDiamonds {
    font-size: 14px;
    text-align: center;
    font-weight: bolder;
    color: #0026ef;
}

.form .note {
    text-align: center;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.form label {
    display: block;
    margin-bottom: 10px;
    color: #333;
}

.form input {
    width: 92%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form input:read-only {
    background-color: #f0f0f0;
}

.iconInputHolder {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.iconInputHolder > img {
    position: fixed;
    margin-left: 0;
}

.iconInputHolder > input {
    width: calc(100% - 60px);
    margin-left: 40px;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.buttonContainer > button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.buttonContainer > button:hover {
    background-color: #0056b3;
}